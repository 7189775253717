// 组件
import alterForm from '@/components/alterForm'
// 接口
import { getPackagesCollection, putPackagesFormData, getPackagesPreview, getGlobaltixPackages, globalTixPackageChange } from '@/services/zoo-tickets.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'
import Utils from '@/utils/Utils'

export default {
  components: {
    alterForm
  },
  data () {
    return {
      collapseLength: [],
      formData: jsonData.formData,
      secFormData: jsonData.secFormData,
      tirFormData: jsonData.tirFormData,
      package_items: [],
      urlParams: {},
      firModifyData: {},
      secModifyData: {}
    }
  },
  watch: {
  },
  computed: {
    topVisible () {
      return Utils.isNull(this.$route.params.ticket_package_id_eq)
    }
  },
  created () {
    this.urlParams = this.$route.params
    this.init()
  },
  mounted () {
    if (this.$route.params.disabled && this.$route.params.disabled === 'true') {
      this.formData[0].createDateItem[5].Object.disabled = true
    } else {
      this.formData[0].createDateItem[5].Object.disabled = false
    }
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getPackagesPreview(this.urlParams.id).then((res) => {
        const { third_party_platform, globaltix_ticket_id } = res.data.package
        this.firModifyData = res.data.package
        this.secModifyData = {
          third_party_platform, globaltix_ticket_id
        }
        if (res.data.package.third_party_platform === 'globaltix') {
          this.globaltixTicket()
          this.handleGlobaltixChange(globaltix_ticket_id)
        }
      })
      getPackagesCollection().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[5].options = res.data.collections.ticket_packages
          // this.formData[0].createDateItem[3].options = res.data.collections.all_ticket_types
          this.secFormData[0].createDateItem[0].options = res.data.collections.third_party_platforms
          this.formData[2].createDateItem[0].createDateFirstItem[2].options = res.data.collections.exceptional_types
        }
      }).finally(() => {
        // this.$store.state.app.loading.close()
      })
    },
    handleChange (data, nextChangeProp, item, createDate) {
      if (item.prop === 'third_party_platform' && data === 'globaltix') {
        this.globaltixTicket()
      }
      if (item.prop === 'globaltix_ticket_id') {
        this.handleGlobaltixChange(createDate.globaltix_ticket_id)
      }
    },
    handleSubmitClick () {
      console.log('提交提交----------->>>>')
      this.$store.state.app.loading = this.$loading(loading)
      const obj_fir = JSON.parse(JSON.stringify(this.$refs.firFormData.createDate))
      const obj_sec = JSON.parse(JSON.stringify(this.$refs.secFormData.createDate))
      const obj_tir = {
        package_items: []
      }
      this.$refs.tirFormData.forEach(tir => {
        obj_tir.package_items.push({
          product_id: tir.createDate.id,
          name: tir.createDate.name,
          name_en: tir.createDate.name_en
        })
      })
      Object.assign(obj_fir, obj_sec, obj_tir)
      // if (!obj_fir.parent_ticket_type_id) obj_fir.parent_ticket_type_id = null
      obj_fir.exceptional_date_ranges_attributes = this.formatLabelDestory('exceptional_date_ranges_attributes', obj_fir)
      const params = {
        package: obj_fir
      }
      putPackagesFormData(this.urlParams.id, params).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            if (this.$route.params.ticketType) {
              this.$router.push({
                name: 'zooTicketsAdd',
                params: {
                  ticket_package_id_eq: this.$route.params.ticket_package_id_eq,
                  ticketType: this.$route.params.ticketType
                }
              })
            } else {
              this.$router.push({
                name: this.topVisible ? 'ticketPackageList' : 'basicTicketPackageList',
                params: {
                  ticket_package_id_eq: this.$route.params.ticket_package_id_eq
                }
              })
            }
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    formatLabelDestory(key, data) {
      if (this.firModifyData[key] && this.firModifyData[key].length === 0) {
        return data[key]
      }

      let delArr = []
      let dataArr = JSON.parse(JSON.stringify(data[key]))

      this.firModifyData[key].map ((label) => {
        if (dataArr && dataArr.length > 0) {
          const index = dataArr.findIndex((item) => label.id === item.id);
          if (index <= -1) {
            label._destroy = 1
            delArr.push(label)
          }
        } else {
          label._destroy = 1
          delArr.push(label)
        }
      })
      return [...delArr,...dataArr]
    },
    globaltixTicket () {
      getGlobaltixPackages().then((res) => {
        this.secFormData[0].createDateItem[1].options = res.data.globaltix_packages
      })
    },
    handleGlobaltixChange (id) {
      this.$store.state.app.loading = this.$loading(loading)
      globalTixPackageChange(id, { ticket_type_id: this.urlParams.id }).then(res => {
        this.$nextTick(() => {
          // this.package_items = res.data.globaltix_package.package_items
          this.package_items = res.data.globaltix_package.package_items.map(packItem => {
            return { ...packItem, name_en: Utils.isNull(packItem.name_en) ? packItem.name : packItem.name_en }
          })
          const { advance_booking_in_minutes, capacity_control, peak_season, visit_date_required, validity_in_days } = res.data.globaltix_package
          this.$refs.firFormData.createDate.capacity_control = capacity_control
          this.$refs.firFormData.createDate.advance_booking_in_minutes = advance_booking_in_minutes
          this.$refs.firFormData.createDate.peak_season = peak_season
          this.$refs.firFormData.createDate.visit_date_required = visit_date_required
          this.$refs.firFormData.createDate.validity_in_days = validity_in_days
          const { t_start_date, t_end_date, t_description, inclusions, terms_and_conditions, use_guide, cancellation_policies, t_max_pax, t_min_pax, settlement_price_f, t_original_price_f, payable_amount_f  } = res.data.globaltix_package
          this.$refs.secFormData.createDate.t_start_date = t_start_date
          this.$refs.secFormData.createDate.t_end_date = t_end_date
          this.$refs.secFormData.createDate.t_max_pax = t_max_pax
          this.$refs.secFormData.createDate.t_min_pax = t_min_pax
          this.$refs.secFormData.createDate.settlement_price_f = settlement_price_f
          this.$refs.secFormData.createDate.t_original_price_f = t_original_price_f
          this.$refs.secFormData.createDate.payable_amount_f = payable_amount_f
          this.$refs.secFormData.createDate.t_description = t_description
          this.$refs.secFormData.createDate.inclusions = inclusions
          this.$refs.secFormData.createDate.terms_and_conditions = terms_and_conditions
          this.$refs.secFormData.createDate.use_guide = use_guide
          this.$refs.secFormData.createDate.cancellation_policies = cancellation_policies
        })
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleCancelClick () {
      this.$router.go(-1)
    }
  }
}
